// Add your custom JS here.
jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop() > 50) {
        jQuery('header.header-site').addClass('scrolled');
    } else {
        jQuery('header.header-site').removeClass('scrolled');
    }
});

jQuery(document).ready(function($) {

    
});

jQuery(document).ready(function($) {
    $('[tabindex="0"]').keydown(function(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        $(this).click();
      }
    });


    $('.form-border-bottom').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
          e.preventDefault();
          return false;
        }
    });
    $('.gfield-choice-input:checkbox').keypress(function(e){
        if((e.keyCode ? e.keyCode : e.which) == 13){
            $(this).trigger('click');
        }
    });
  });

  /*$('.gfield_checkbox .gfield-choice-input').on('keypress', function (event) {
        if (event.which === 13) {
            this.checked = !this.checked;
        }
    });*/
   


/*skip content*/
document.addEventListener('DOMContentLoaded', function() {
    const LinkGoSecondSection = document.getElementById('GoSecondSection');
    LinkGoSecondSection.addEventListener('click', function(event) {
        event.preventDefault();
        var secondSection = document.querySelector('.general-section:nth-of-type(1)');
        if(document.querySelectorAll('.general-section').length > 1) {
            secondSection = document.querySelector('.general-section:nth-of-type(2)');
        }
        if(document.querySelector('.skip-link-anchor')) {
            secondSection = document.querySelector('.skip-link-anchor');
        }
       

        
        
        if (secondSection) {
            secondSection.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('the second section was not found.');
        }
    });
});



function autosize() {
    var el = this;
    setTimeout(function() {
        el.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';
        el.style.cssText = 'height:' + (el.scrollHeight + 2) + 'px';
    }, 0);
}

jQuery(document).ready(function () {
    //tabs content
    let tabs = jQuery(".content-accordion .tab-content");
    //let buttonTabs = jQuery('.container-single-custom .tab-title-item');

    jQuery('.content-accordion').on('click', '.tab-title', function () {
        let id = '';

        jQuery('.content-accordion .tab-title').removeClass('active');
        jQuery(this).addClass('active');
        id = jQuery(this).prop('id');

        for (let item of tabs) {
            if (item.classList.contains(id)) {
                
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }
        }
        jQuery("html,body").animate({scrollTop: jQuery(this).offset().top - 150}, 1000);
    });
});

jQuery(document).ready(function($) {

    /* Search - Nav */
    var searchMenuLink = $('.dropdown-toggle-search');
    var searchDropdown = $('.dropdown-menu--search');

    searchMenuLink.on('click', function(e) {
        e.preventDefault();
        searchDropdown.toggle();
        searchMenuLink.toggleClass('open');
    });

    $(document).on('click', function(e) {
        if (!$(e.target).closest('.dropdown-menu--search').length && !$(e.target).closest('.dropdown-toggle-search').length) {
            searchDropdown.hide();
            searchMenuLink.removeClass('open');
        }
    });
    
    jQuery('.top-alert .btn-close').click(function(e) {
        jQuery('body').addClass('close-top');
    });
    jQuery('.navbar-toggler').click(function(e) {
        jQuery('body').toggleClass('open-menu');
    });
    jQuery('.drop-down-icon-mobile').click(function(){
        if(jQuery(this).parent().hasClass('active-nav')){
            jQuery(this).parent().removeClass('active-nav');
        }else{
            jQuery(this).parent().addClass('active-nav');
        }
    }); 

    jQuery('.open-popup-link-team').magnificPopup({
        type: 'inline',
        midClick: true,
        mainClass: 'mfp-fade'
      });

      jQuery('.open-popup-link-brokers').magnificPopup({
        type: 'inline',
        midClick: true,
        mainClass: 'mfp-fade'
      });
});

// remove the word ‘search results for...’ from your search
jQuery('.excerpt_part').each(function() {
    var element = jQuery(this);
    
    // Remove the word ‘Search results for’
    if (element.text().includes("Search results for")) {
        element.html(element.html().replace("Search results for", ""));
    }

    // Remove the strong label
    element.find('strong').hide();

    // Remove the two remaining inverted commas
    var text = element.html();
    var count = 0;
    element.html(text.replace(/‘/g, function(match) {
        count++;
        return count <= 2 ? '' : match;
    }));
});